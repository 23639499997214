<template>
	<header id="page-header" role="banner">
		<slot name="before" />
		<div
			class="page-header page-header--fixed"
			:class="{shrink: isSmallHeader}"
		>
			<nav class="navbar navbar-expand ps-lg-5 pe-lg-5 m-0" role="navigation"
				data-aos="fade-down"
				data-aos-duration="800"
				data-aos-delay="800"
			>
				<slot name="logo" />
				<slot name="navigation" />
			</nav>
		</div>
		<slot name="after" />
		<slot name="headvisual" />
	</header>
</template>

<script>
import Headvisual from '~/components/organisms/hero/Headvisual';
export default {
	name: 'PageHeader',
	components: {
		Headvisual
	},
	data() {
		return {
			isSmallHeader: false
		};
	},
	mounted() {
		window.document.onscroll = () => {
			const PageHeader = document.getElementById('page-header');
			if (window.scrollY > PageHeader.offsetTop) {
				this.isSmallHeader = true;
			} else {
				this.isSmallHeader = false;
			}
		}
	},
	methods: {
		toggleHeaderClass() {
			return this.isSmallHeader
				? 'sticky-header'
				: 'page-header'
			;
		}
	}
}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$page-header--background: #fff !default;
	$page-header--color: $primary !default;
	$page-header--z-index: 100 !default;

	$page-header--item--color: $page-header--color !default;
	$page-header--shrink--transition-timing-function: $default--animation-easing !default;
	$page-header--shrink--transition-duration: $default--animation-duration !default;

	.page-header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		color: $page-header--color;

		z-index: $page-header--z-index;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			box-shadow: 0 5px 6px rgba(#000, 0.16);
			z-index: -1;

			@include media-breakpoint-up(xl) {
				opacity: 0;
			}
		}

	}

	.page-header--fixed {
		position: relative;

		@include media-breakpoint-down(xl) {
			background: $page-header--background;
			padding-top: 0;
		}

		@include media-breakpoint-up(md) {
			position: fixed;
		}
	}

	.page-header {

		@include media-breakpoint-up(xl) {
			.navbar {
				align-items: flex-end;
				padding-top: 20px;
			}

			&.shrink {

				background: $page-header--background;

				&:after {
					opacity: 1;
					transition: ($page-header--shrink--transition-timing-function) ($page-header--shrink--transition-duration);
					transition-property: opacity;
					will-change: opacity;
				}

				.navbar {
					padding: 10px 0 10px 0;
					min-height: 50px;
				}
			}
		}

		.navbar {
			@include media-breakpoint-down(lg) {
				transition: none!important;
				transform: none!important;
			}
		}
	}
</style>
