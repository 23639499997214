<template>
	<footer class="footer-main">
		<PageFooterGoogleMap
			v-if="backendLayout === 'HeroImage' || backendLayout === 'Default' || backendLayout === 'Jobmarket'|| backendLayout === 'NewsDetail'"
		/>
		<PageFooterLinks />
	</footer>
</template>

<script>
import PageFooterGoogleMap from '~/components/organisms/map/PageFooterGoogleMap';
import PageFooterLinks from '~/components/molecules/navigation/PageFooterLinks';
import { mapState } from 'vuex';

export default {
	name: 'PageFooter',
	props: {
		Default: {
			type: String
		},
	},
	components: {
		PageFooterGoogleMap,
		PageFooterLinks
	},
	computed: {
		...mapState({
			backendLayout: (state) => {
				return state.typo3?.page?.page?.appearance?.backendLayout;
			},
		}),
	}
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
$footer-main--font-size: rem(15) !default;
$footer-main--padding: 30px 20px 30px 20px !default;
$footer-main--padding-sm: 30px 20px 100px 20px !default;

.footer-main--links {
    padding: $footer-main--padding-sm;
    text-align: center;
    font-size: $footer-main--font-size;

	@include media-breakpoint-up(lg) {
		padding: $footer-main--padding;
	}

   & > div {
        display: flex;
        align-items: center;
    }
}
</style>
